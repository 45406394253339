@use '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.description-block {
    width: 100%;
    margin-bottom: 192px;
    color: $white;
    @include mixins.flex(row, center, center);

    @media (max-width: 850px) {
        margin-bottom: 96px;
    }

    .description-block-container {
        width: 100%;
        max-width: 1200px;
        @include mixins.flex(row, space-between, flex-start);

        @media (max-width: 1260px) {
            width: calc(100% - 40px);
            margin: auto 20px;
        }

        @media (max-width: 850px) {
            gap: 32px;
            @include mixins.flex(column, flex-start, flex-start);
        }

        @media (max-width: 500px) {
            width: calc(100% - 32px);
            margin: auto 16px;
        }


        .description-block-title {
            height: 595px;
            @include mixins.flex(column, space-between, flex-start);

            @media (max-width: 1100px) {
                height: 400px;
            }

            @media (max-width: 850px) {
                width: 100%;
                height: auto;
                @include mixins.flex(row, space-between, flex-start);
            }

            @media (max-width: 500px) {
                @include mixins.flex(row, space-between, center);
            }

            span {
                width: 260px;
                text-transform: uppercase;
                @include mixins.font(Forum, 60px, normal, 400, 120%);

                @media (max-width: 1100px) {
                    width: 90%;
                }

                @media (max-width: 850px) {
                    width: 265px;
                }

                @media (max-width: 500px) {
                    width: 168px;
                    @include mixins.font(Forum, 40px, normal, 400, 120%);
                }
            }

            div {
                width: 285px;
                height: 389px;
                border-radius: 142.5px 142.5px 0px 0px;
                background-image: url('../../images/description-block-photo.jpeg');
                background-repeat: no-repeat;
                background-size: 100% auto;
                background-position: 94% 30%;

                @media (max-width: 1100px) {
                    width: 181px;
                    height: 247px;
                }

                @media (max-width: 500px) {
                    width: 84px;
                    height: 96px;
                    background-size: 130% auto;
                    background-position: 50% 27%;
                }
            }
        }

        .description-block-text {
            gap: 40px;
            @include mixins.flex(column, flex-start, flex-start);

            @media (max-width: 850px) {
                gap: 16px;
            }
        }
    }
}