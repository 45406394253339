@use '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

li {
    color: $white;
    overflow: hidden;
    @include mixins.font(Forum, 18px, normal, 400, 120%);

    @media (max-width: 850px) {
        width: 100%;
        height: 78px;
        border-bottom: 1px solid $line-color;
        @include mixins.font(Forum, 32px, normal, 400, 120%);
        @include mixins.flex(column, center, center);
    }

    @media (max-width: 500px) {
        @include mixins.flex(column, center, flex-start);
    }

    span {
        color: $white;
        transition: color 0.4s ease-in-out;
        display: block;
        cursor: pointer;
    }

    div{
        width: 100%;
        height: 1.5px;
        background-color: $accent-color;
        opacity: 0.1;
        transform: translateX(-100%);
        transition: transform 0.4s, opacity 0.7s;

        @media (max-width: 850px) {
            display: none;
        }
    }
}

li:hover div {
    transform: translateX(0);
    opacity: 1;
}

li:hover span {
    color: $accent-color;
}