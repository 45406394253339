@use '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.project-block {
    width: 100%;
    padding-bottom: 192px;
    color: $white;
    @include mixins.flex(row, center, center);

    @media (max-width: 850px) {
        padding-bottom: 96px;
    }

    .project-block-container {
        width: 100%;
        max-width: 1440px;
        gap: 64px;
        @include mixins.flex(column, flex-start, flex-start);

        @media (max-width: 700px) {
            gap: 56px;
        }

        @media (max-width: 500px) {
            
        }

        .project-block-title {
            width: calc(100% - 240px);
            padding: 0 120px;
            @include mixins.flex(row, space-between, center);

            @media (max-width: 1260px) {
                width: calc(100% - 40px);
                padding: 0 20px;
            }

            @media (max-width: 700px) {
                @include mixins.flex(column, flex-start, flex-start);
            }

            h3 {
                width: 244px;
                margin: 0;
                text-transform: uppercase;
                @include mixins.font(Forum, 60px, normal, 400, 120%);

                @media (max-width: 700px) {
                    width: unset;
                    gap: 8px;
                    @include mixins.font(Forum, 40px, normal, 400, 120%);
                }
            }

            span {
                width: 386px;
                @include mixins.font(Forum, 22px, normal, 400, 120%);

                @media (max-width: 700px) {
                    width: unset;
                    @include mixins.font(Forum, 16px, normal, 400, 120%);
                }
            }
        }

        .project-block-info-box {
            width: calc(100% - 240px);
            padding: 0 120px;
            gap: 56px;
            @include mixins.flex(column, flex-start, flex-start);


            @media (max-width: 1430px) {
                width: calc(100% - 120px);
                padding: 0 120px 0 0;
            }

            @media (max-width: 1260px) {
                width: calc(100% - 20px);
                padding: 0 20px 0 0;
            }

            @media (max-width: 700px) {
                width: 100%;
                padding: 0;
            }

        }
    }
}