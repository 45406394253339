// Colors

$main-color: #252429;
$block-color: #343239;
$line-color: #403E4B;
$accent-color: #DC5A81;
$accent-color-dark: #6F3143;
$accent-color-opacity: rgba(255, 255, 255, 0.5);
$transparent: transparent;
$white: #FFFFFF;
$black: #303030;
$black-opacity: rgba(48, 48, 48, 0.5);

