@use '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.faq-item {
    width: 100%;
    height: auto;
    padding: 30px 0;
    border-bottom: 1px solid $line-color;
    position: relative;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    cursor: pointer;

    &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $accent-color-dark;
        transform: translateY(100%);
        transition: all 0.5s ease-in-out;
    }

    .faq-item-question {
        height: auto;
        transition: all 0.5s ease-in-out;
        position: relative;
        z-index: 2;
        @include mixins.flex(row, space-between, center);

        span {
            width: 600px;
            @include mixins.font(Forum, 32px, normal, 400, 110%);

            @media (max-width: 750px) {
                width: 75%;
            }

            @media (max-width: 600px) {
                width: 80%;
                @include mixins.font(Forum, 28px, normal, 400, 90%);
            }

            @media (max-width: 400px) {
                width: 75%;
            }
        }
        
        div {
            width: 53px;
            height: 53px;
            border-radius: 1000px;
            border: 1px solid $transparent;
            background-color: $main-color;
            transition: all 0.5s ease-in-out;

            .faq-item-arrow {
                transition: all 0.5s ease-in-out;
                color: $white;
    
                &.open {
                    transform: rotate3d(1, 0, 0, 180deg);
                }
            }
        }
    }

    .faq-item-answer {
        width: 600px;
        height: auto;
        margin-top: 0px;
        overflow: hidden;
        opacity: 0;
        transform: translateY(-10px);
        transition: all 0.5s ease-in-out;
        @include mixins.font(Forum, 18px, normal, 400, 120%);

        @media (max-width: 700px) {
            width: calc(100% - 24px);
        }

        @media (max-width: 600px) {
            width: calc(100% - 12px);
        }

        @media (max-width: 600px) {
            transform: translateY(-5px);
            @include mixins.font(Forum, 16px, normal, 400, 120%);
        }

        &.open {
            margin-top: 16px;
            transform: translateY(0px);
            opacity: 1;
        }
    }
}

// Hover Effect

.faq-item:hover .faq-item-question {
    padding: 0px 24px;

    @media (max-width: 600px) {
        padding: 0px 12px;
    }

    div {
        background-color: $white;
        // border: 1px solid $white;
    }

    .faq-item-arrow {
        color: $accent-color-dark;
    }
}

.faq-item:hover .faq-item-answer {
    margin-left: 24px;

    @media (max-width: 600px) {
         margin-left: 12px;
    }
}

.faq-item:hover:before {
    transform: translateY(0);
}

