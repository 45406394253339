@use '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.project-item {
    width: 100%;
    @include mixins.flex(column, flex-start, center);

    .project-item-title {
        width: 100%;
        gap: 32px;
        @include mixins.flex(row, space-between, center);

        @media (max-width: 1430px) {
            width: calc(100% - 120px);
            padding-left: 120px;
        }

        @media (max-width: 1260px) {
            width: calc(100% - 20px);
            padding-left: 20px;
        }

        @media (max-width: 500px) {
            width: calc(100% - 16px);
            padding-left: 16px;
        }

        h4 {
            text-transform: uppercase;
            white-space: nowrap;
            @include mixins.font(Forum, 32px, normal, 400, 120%);

            @media (max-width: 500px) {
                @include mixins.font(Forum, 20px, normal, 400, 120%);
            }
        }

        div {
            background-color: $white;
            height: 1px;
            width: 100%;

            @media (max-width: 700px) {
                margin-right: 20px;
            }

            @media (max-width: 500px) {
                margin-right: 16px;
            }
        }
    }

    .project-item-info-box {
        width: 100%;
        gap: 20px;
        @include mixins.flex(row, center, center);

        @media (max-width: 700px) {
            @include mixins.flex(column, center, center);
        }

        .project-slider {
            width: calc(100% - 305px);
            overflow: hidden;
            gap: 20px;
            @include mixins.flex(row, center, center);

            @media (max-width: 700px) {
                width: 100%;
            }

            .project-slider-box {
                gap: 20px;
                @include mixins.flex(row, center, center);

                @media (max-width: 1430px) {
                    animation: 20s slide infinite linear;
                }
            }

            .project-image {
                min-width: 285px;
                max-width: 285px;
    
                img {
                    width: 100%;
                }
            }
        }

        .project-video {
            width: 285px;
            height: 439px;
            position: relative;

            @media (max-width: 500px) {
                width: calc(100% - 32px);
                height: 528px;
                padding: 0 16px;
            }

            .cross-icon {
                position: absolute;
                top: 0px;
                right: 10px;
    
                @media (min-width: 700px) {
                    display: none;
                }
            }

            .project-video-player {
                width: 100% !important;
                height: 100% !important;
    
                iframe {
                    width: 100% !important;
                    height: 100% !important;
                }
            }

            .project-video-container {
                width: 285px;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 3;
                cursor: pointer;
                @include mixins.flex(row, center, center);

                @media (max-width: 500px) {
                    overflow: hidden;
                    width: calc(100% - 32px);
                    height: 528px;
                    padding: 0 16px;
                }

                .project-video-button {
                    width: 170px;
                    height: 170px;
                    border-radius: 1000px;
                    background-color: $accent-color-dark;
                    z-index: 4;
                    position: absolute;
                    text-align: center;
                    @include mixins.flex(row, center, center);
                    @include mixins.font(Forum, 22px, normal, 400, 120%);
                }

                .project-video-preloader {
                    width: 100%;

                    @media (max-width: 500px) {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}

@keyframes slide {
    from {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    to {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}


// // Slider Styles

// .project-block-info-box {

//     .slick-slider {
//         width: 100% !important;
//         max-width: 895px !important;
//         position: static !important;

//         // @media (max-width: 1260px) {
//         //     width: 407px !important;
//         // }

//         // @media (max-width: 800px) {
//         //     width: 340px !important;
//         // }
//     }

//     .slick-track{
//         display: flex !important;
//         width: auto !important;
//     }

//     .slick-list {
//         overflow: visible !important;
//     }

//     .slick-slide {
//         width: 305px !important;
//         min-width: 305px !important;

//         // @media (max-width: 800px) {
//         //     width: 340px !important;
//         //     min-width: 340px !important;
//         // }
//     }

//     .slick-dots {
//         display: none !important;
//     }
// }